<template>
    <div class="plan_chart" :style="{ 'height': height }">
        <div class="target_chart" :style="{ 'height': height }" ref="target_chart"></div>
    </div>
</template>

<script>
import * as eCharts from 'echarts';
import { common } from '../../assets/utils/common';

const startColor = [186, 158, 255];
const endColor = [255, 155, 134];
export default {
    name: 'plan_chart',
    props: {
        height: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            userWeight: 0,
            targetWeight: 0,
            unit: 'kg',
            falseData: [300, 200, 180, 100],
        }
    },
    created() {
        this.initData();
    },
    mounted() {
        this.setECharts();
    },
    methods: {
        initData() {
            let localList = this.$changeLocal.getItem('localList');
            if (localList) {
                let messageList = localList;
                let targetWeightInfo = messageList.filter(ite => ite.key == 'targetWeight')[0];
                let weightInfo = messageList.filter(ite => ite.key == 'weight')[0];
                if (!targetWeightInfo || !weightInfo) return;
                if (targetWeightInfo.value.indexOf('kg') != -1) {
                    this.targetWeight = parseInt(targetWeightInfo.value.split('kg')[0]);
                    this.userWeight = parseInt(weightInfo.value.split('kg')[0]);
                    this.unit = 'kg';
                } else {
                    this.targetWeight = parseInt(targetWeightInfo.value.split('lbs')[0]);
                    this.userWeight = parseInt(weightInfo.value.split('lbs')[0]);
                    this.unit = 'lbs';
                }
            }
        },
        // 获取当前月份有多少天
        getCountDays() {
            var curDate = new Date();
            var curMonth = curDate.getMonth();
            curDate.setMonth(curMonth + 1);
            curDate.setDate(0);
            return curDate.getDate();
        },
        // 设置数据可视化
        setECharts() {
            // 设置体重数据
            let differenceValue = this.userWeight - this.targetWeight;
            // 体重变化列表
            let weightChangeList = [
                this.userWeight,
                differenceValue * 0.8 + this.targetWeight,
                differenceValue * 0.5 + this.targetWeight,
                this.targetWeight,
            ];
            // 获取当前时间数据
            let nowTime = new Date();
            // 获取当前年份
            let year = nowTime.getFullYear();
            // 获取当前月份
            let month = nowTime.getMonth() + 1;
            // 获取当前日期
            let day = nowTime.getDate();
            // 装载最终结果的列表
            let monthChangeList = [];
            // 获取当前月份还剩几周
            let surplusWeek = Math.round((this.getCountDays() - day) / 7);
            // 使用体重变化列表和时间变化列表一一对应
            weightChangeList.forEach((item) => {
                // 算法：减去1kg要使用1week，用前后体重差值（有多少kg就有多少week）减去这个月还剩的周数/4（一个月约等于4周）=需要多少个月
                let differenceMonth = (differenceValue - (item - this.targetWeight) - surplusWeek) / 4 + month;
                // 获取下标需要-1
                let monthStr = common.monthList[Math.ceil(differenceMonth - 1)];
                if (differenceMonth > 12) {
                    monthStr = `${common.monthList[Math.round(differenceMonth % 12) - 1]}
${year + Math.floor(differenceMonth / 12)}`
                }
                monthChangeList.push(monthStr);
            })
            // 基于准备好的dom，初始化eCharts实例
            let myChart = eCharts.init(this.$refs.target_chart);
            // 绘制图表
            myChart.setOption({
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: monthChangeList,
                    axisTick: {
                        alignWithLabel: false
                    },
                    axisLabel: {
                        color: '#777777'
                    },
                    axisLine: {
                        onZero: true,
                        lineStyle: {
                            color: '#fff',
                        }
                    },
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    max: 360,
                    min: 0,
                },
                legend: {
                    show: false,
                },
                series: [{
                    // 设置气泡里的文字以及气泡的样式
                    markPoint: {
                        label: {
                            color: '#fff',
                            show: true,
                            fontSize: 12,
                            position: [5, 4],
                            formatter: (data) => {
                                let ind = this.falseData.indexOf(data.value);
                                let tooShort = weightChangeList[ind].toFixed(1).length < 5;
                                return `${tooShort ? ' ' : ''}${weightChangeList[ind].toFixed(1)} ${this.unit}`
                            }
                        },
                        data: this.falseData.map((item, ind) => {
                            let chartBackground = `${this.$store.state.assetsUrl}/imgs/sign/chart_background${ind + 1}.webp`;
                            return {
                                value: item,
                                xAxis: ind,
                                yAxis: item,
                                coord: [ind, item + 60],
                                symbolSize: [58, 32],
                                symbol: `image://${chartBackground}`,
                            };
                        })
                    },
                    data: this.falseData,
                    type: 'line',
                    symbol: (rawValue) => {
                        let ind = this.falseData.indexOf(rawValue);
                        let circle = `${this.$store.state.assetsUrl}/imgs/sign/circle${ind + 1}.png`;

                        return `image://${circle}`;
                    },
                    symbolSize: 10,
                    smooth: true,
                    showAllSymbol: false,
                    // 设置数据走势线条的样式
                    lineStyle: {
                        width: 5,
                        color: {
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0,
                                color: `rgb(${startColor[0]},${startColor[1]},${startColor[2]})`,
                            },
                            {
                                offset: 1,
                                color: `rgb(${endColor[0]},${endColor[1]},${endColor[2]})`,
                            },
                            ],
                            globalCoord: false,
                        }
                    },
                    // 设置数据内的样式
                    areaStyle: {
                        color: {
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0,
                                color: `rgba(${startColor[0]},${startColor[1]},${startColor[2]},0.15)`,
                            },
                            {
                                offset: 1,
                                color: `rgba(${endColor[0]},${endColor[1]},${endColor[2]},0.15)`,
                            },
                            ],
                            globalCoord: false,
                        }
                    },
                }]
            });
        },
    }

}
</script>

<style scoped lang="less">
.plan_chart {
    width: 100%;
    height: 100%;
    padding-top: 1px;
    position: relative;

    img {
        pointer-events: none;
    }
}

.target_chart {
    position: absolute;
    height: 120%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>